import { render, staticRenderFns } from "./SessionSpeakerAlt.vue?vue&type=template&id=a51151bc&scoped=true&"
import script from "./SessionSpeakerAlt.vue?vue&type=script&lang=js&"
export * from "./SessionSpeakerAlt.vue?vue&type=script&lang=js&"
import style0 from "./SessionSpeakerAlt.vue?vue&type=style&index=0&id=a51151bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a51151bc",
  null
  
)

export default component.exports