<template>
  <div class="page-layout is-flex is-flex-direction-column">
    <div class="external-page-container is-flex-grow-1">
      <slot></slot>
    </div>
    <footer-view class="footer"></footer-view>
  </div>
</template>

<script>
import FooterView from "@/shared/components/Footer";

export default {
  name: "ExternalPageLayout",

  components: {
    FooterView,
  },
};
</script>

<style lang="scss" scoped>
.page-layout h1 {
  margin-bottom: 40px;
  text-transform: uppercase;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #6784ff;

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

.page-layout .page-loader {
  padding: 20px 0;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-layout .loading-error {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    padding: 18px 24px;
    background: #ffc0d0;
    border-radius: 16px;
    line-height: 1;
    text-align: center;
    font-size: 24px;
    color: #dd0000;
  }
}

.external-page-container {
  padding: 120px 60px 40px 40px;
  display: flex;
  flex-direction: column;
  //max-width: 900px;

  @media (max-width: 1023px) {
    padding-top: 80px;
  }

  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 60px;
  }
}

.powered-by {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  text-align: center;

  p {
    margin: 0 0 4px 0;
  }
}

.page-layout {
  height: 100vh;
  width: 100%;
}

.footer {
  width: 100%;
}
</style>
