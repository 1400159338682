<template>
  <div class="avatar">
    <img v-if="picture && !imageError" :src="imageUrl" :title="fullName" @error="imageError = true" />
    <div v-else class="avatar-initials">
      <p>{{ initials }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["firstName", "lastName", "picture"],

  data() {
    return {
      imageError: false,
    };
  },

  computed: {
    imageUrl() {
      return this.picture && this.picture.file_thumbnail_200_url;
    },

    fullName() {
      const firstName = this.firstName;
      const lastName = this.lastName;

      return `${firstName} ${lastName}`;
    },

    initials() {
      const segments = [this.firstName || "", this.lastName || ""].filter(segment => !!segment);
      const initials = segments.map(segment => segment[0].toUpperCase());

      return initials.join("");
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  margin-right: 16px;
  background: #f0f0f0;
  border-radius: 100%;
  overflow: hidden;
  user-select: none;
  width: 48px;
  height: 48px;
  flex: 0 0 48px;
}

.avatar-initials {
  letter-spacing: 1.6px;
  font-size: 14px;
  color: #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
}

img {
  object-fit: cover;
  width: inherit;
  height: inherit;
}

p {
  margin: 0;
}
</style>
