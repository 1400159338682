<template>
  <div class="leaderboard-row">
    <div class="leaderboard-column">
      {{ place }}
    </div>

    <div class="leaderboard-column">
      <div class="leaderboard-user">
        <avatar :firstName="firstName" :lastName="lastName" :picture="picture"></avatar>
        <p>{{ fullName }}</p>
      </div>

      <div class="mobile-details">
        <p>
          {{ $t("leaderboard.score_column") }}: <span class="text-nowrap">{{ score }}</span>
        </p>
        <p>
          {{ $t("leaderboard.completed_on_column") }}: <span class="text-nowrap">{{ completedOn }}</span>
        </p>
      </div>
    </div>

    <div class="leaderboard-column">
      {{ score }}
    </div>

    <div class="leaderboard-column">
      {{ completedOn }}
    </div>
  </div>
</template>

<script>
import dayjs from "@/shared/utils/day";

import Avatar from "@/web/components/external/Avatar";

export default {
  components: {
    Avatar,
  },

  props: ["place", "value"],

  computed: {
    fullName() {
      return `${this.firstName} ${this.lastName}`;
    },

    firstName() {
      return this.value.user && this.value.user.first_name;
    },

    lastName() {
      return this.value.user && this.value.user.last_name;
    },

    picture() {
      return this.value.user && this.value.user.picture;
    },

    score() {
      return this.value.points;
    },

    completedOn() {
      const date = this.value.last_response;
      return dayjs(date).format("DD.MM.YYYY HH:mm:ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.leaderboard-row {
  border-bottom: solid 1px #f0f0f0;
}

.leaderboard-column {
  font-weight: 600;
  overflow: hidden;

  @media (max-width: 767px) {
    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }
  }
}

.leaderboard-user {
  display: flex;
  align-items: center;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 767px) {
    margin-bottom: 8px;
  }
}

.mobile-details {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

p {
  margin: 0;
}
</style>
