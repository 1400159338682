<template>
  <div class="survey-answer-closed">
    <div class="survey-answer-progress" :style="progressStyle"></div>
    <div class="survey-answer-label" :style="labelStyle">
      <p>
        <strong>{{ progressLabel }}</strong>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["responses", "progress"],

  computed: {
    progressStyle() {
      return {
        width: `${this.progressPercent}%`,
      };
    },

    labelStyle() {
      return {
        width: `${100 - this.progressPercent}%`,
      };
    },

    progressLabel() {
      return `${this.progressPercent}%`;
    },

    progressPercent() {
      return Math.floor(this.progress * 100.0);
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-answer-closed {
  background-color: #f3f5ff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  position: relative;

  @media (max-width: 767px) {
    border-radius: 6px;
    height: 20px;
  }
}

.survey-answer-progress {
  background-color: #6784ff;
  border-radius: inherit;
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.survey-answer-label {
  padding: 0 8px;
  display: flex;
  min-width: fit-content;
  width: 50%;

  position: relative;
  z-index: 1;
  top: 4px;

  @media (max-width: 767px) {
    top: 2px;
  }
}

p {
  font-size: 24px;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 14px;
  }
}
</style>
